




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.add_group_with_rtl {
  .el-form-item__label {
    width: 100%;
  }
  .el-input__inner {
    text-align: right;
  }
}

.just_for_print_group {
  @media print {
    * {
      -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
      color-adjust: exact !important; /*Firefox*/
    }
    // patterns
    .precision {
      background-color: #3c3;
    }

    .technical_reasoning {
      background-color: #f93;
    }
    .sequence {
      background-color: #39c;
    }

    .confluence {
      background-color: #c36;
    }
    .server_table_for_big_screens {
      display: block !important;
    }
    .server_table_for_mobile_screen {
      display: none !important;
    }
    // .server_table_just_for_mobile_screen {
    //   display: none !important;
    // }
  }
}
